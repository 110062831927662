import React from 'react';
import { graphql } from 'gatsby';
import { Grid, Row, Col } from 'react-flexbox-grid';

import Layout from '../../components/Layout';
import Header from '../../components/Header';
import Breaker from '../../components/Breaker';
import SEO from '../../components/Seo';
import { COACHING_PATH } from '../../constants/Routes';

import { Avatar, Content, Back } from './style';

const Testimony = ({ data, location: { hash } }) => {
  const post = data.markdownRemark

  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        keywords={[`paleo`, `health`, `wellness`, `coach`, `fitness`, `coaching`, `chef`, `mindfulness`, `desporto`, `gym`, `ginasio`]}
      />
      <Header />

      <Grid>
        <Row center="xs">
          <Col md={8}>
            <Avatar src={post.frontmatter.cover} alt={post.frontmatter.title} />
            <h1>{post.frontmatter.title}</h1>

            <Content className="post-content" dangerouslySetInnerHTML={{ __html: post.html }} />
          </Col>
        </Row>

        <Breaker />

        <Row center="xs">
          <Col md={12}>
            <Back to={COACHING_PATH}>
              Voltar a testemunhos
            </Back>
          </Col>
        </Row>
      </Grid>
    </Layout>
  );
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        author {
          name
        }
				cover
      }
    }
  }
`

export default Testimony
