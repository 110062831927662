import styled from 'styled-components';
import { Link } from 'gatsby';

export const Avatar = styled.img`
  width: 150px;
  border-radius: 250px;
  display: inline-block;
  padding: 4px;
  border: 2px #ccc solid;
  margin: 50px auto 20px;
`;

export const Content = styled.div`
  font-size: 19px;
  line-height: 1.6;
  text-align: left;
`;

export const Back = styled(Link)`
  background: #faab06;
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: -.5px;
  padding: 15px 30px;
  border-radius: 6px;
`;
